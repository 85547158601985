import React, { useMemo } from 'react';

import { useStyles } from '@wix/tpa-settings/react';

import { buildCoverImage } from './cover-image';
import { getRandomThemeColorParam, getBackgroundColorParam } from './colors';

export function useRandomCoverImage() {
  const styles = useStyles();

  const [image, setImage] = React.useState<File>();
  const [isLoading, setIsLoading] = React.useState(true);

  const color = useMemo(() => styles.get(getRandomThemeColorParam()), []);
  const background = useMemo(() => styles.get(getBackgroundColorParam()), []);

  React.useEffect(() => {
    setIsLoading(true);

    buildCoverImage(color.value, background.value)
      .then(setImage)
      .finally(() => setIsLoading(false));
  }, [color.value, background.value]);

  return {
    image,
    isLoading,
  };
}
